// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tarot-de-berlin-tsx": () => import("./../src/pages/preview/tarot-de-berlin.tsx" /* webpackChunkName: "component---src-pages-preview-tarot-de-berlin-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-pages-things-template-tsx": () => import("./../src/templates/pages/things.template.tsx" /* webpackChunkName: "component---src-templates-pages-things-template-tsx" */),
  "component---src-templates-posts-author-template-tsx": () => import("./../src/templates/posts/author.template.tsx" /* webpackChunkName: "component---src-templates-posts-author-template-tsx" */),
  "component---src-templates-posts-thing-template-tsx": () => import("./../src/templates/posts/thing.template.tsx" /* webpackChunkName: "component---src-templates-posts-thing-template-tsx" */)
}

